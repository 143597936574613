
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEAccordion from '@/components/pde/PDEAccordion.vue'
import PDESvg from '@/components/pde/PDESvg.vue'

export default {
  name: 'WhatToExpect',

  components: {
    PDEHeading,
    PDEAccordion,
    PDESvg,
  },

  data() {
    return {
      accordions: [
        {
          id: 'application',
          title: 'Application',
          content: `<p class="mt-0">
                Your rep will let you know when it’s time to begin the application. Completing the application is key to timely credentialing. The application will take about 1-2 hours to complete.
              </p>
              <p class="font-700 mb-0">
                Documents
              </p>
              <p class="mt-0">
                Gather documents up front to streamline the process:
              </p>
              <ul class="list-disc list-inside ml-8 mb-12 marker:text-8">
                <li>Diplomas</li>
                <li>Certifications</li>
                <li>Licenses</li>
                <li>Immunization records</li>
                <li>Procedure logs</li>
                <li>Malpractice history</li>
              </ul>
              <p>We will pre-fill your application, leaving you to answer questions, select privileges, and provide signatures.</p>
              <p>
                Facilities may request additional items like TB tests, CME docs, or updated DEA details. Please reach out to your consultant with any questions - we're here to help and make credentialing as smooth as possible.
              </p>`,
        },
        {
          id: 'presentation',
          title: 'Presentation',
          content: `<p class="mt-0">
                At this stage, your profile, CV, and general availability have been sent to the client so they have everything they need to determine if there is a match. You are not committed to the position until you accept an offer and move to the confirmation stage.
              </p>
              <p class="font-700 mb-0">
                Communication
              </p>
              <p class="mt-0">
                It is important to be in regular communication with your rep and responsive when we reach out. Jobs come and go quickly and we don’t want you to miss the perfect opportunity!
              </p>
              <p class="font-700 mb-0">
                Licensing
              </p>
              <p class="mt-0">
                Don’t worry about licensing. If you accept an offer, we will help you obtain all licenses needed.
              </p>
              <p class="font-700 mb-0">
                Certifications
              </p>
              <p class="mt-0">
                Ensure your board certifications are current, as they’re important at this stage. We’ll keep you informed every step of the way to make the process seamless.
              </p>`,
        },
        {
          id: 'confirmation',
          title: 'Confirmation',
          content: `<p class="mt-0">
                This is when we sort out the fine details. We will work with you to finalize specific schedules, rates and other details. You’ll be credentialed by both us and the site you will be working at. You will now be assigned an Assignment Coordinator (AC) to assist with managing credentialing, travel, and housing.
              </p>

              <p>
                Staying in touch with your AC and meeting document deadlines is key to starting on time.
              </p>
              <p class="font-700 mb-0">
                Credentialing
              </p>
              <p class="mt-0">
                Credentialing includes licensing and hospital privileging.. While it may feel overwhelming, our Hospital Privileging Coordinators (HPCs) will guide you and work with the site’s medical staff to ease the process.
              </p>
              <p class="font-700 mb-0">
                Availability
              </p>
              <p class="mt-0">
                Share any scheduling conflicts early to avoid delays, and HPCs will help complete your file efficiently.
              </p>`,
        },
        {
          id: 'assignment',
          title: 'On Assignment',
          content: `<p class="mt-0">
                Even though you’re now on assignment, your communication with your recruiter doesn’t stop here. Your recruiter is still your main point of contact and is available to assist you with anything you need along the way. Whether you have questions, need support, or feel overwhelmed, don’t hesitate to reach out—we’re here to help!
              </p>
              <p class="font-700 mb-12">
                Reminders
              </p>
              <ul class="list-disc list-outside ml-16 mb-12 marker:text-8">
                <li>Timecards: Make sure to submit your timecards promptly to ensure accurate and timely payment.</li>
                <li>Reimbursements: We recommend submitting reimbursements weekly with your timecard.</li>
              </ul>
              <p class="font-700 mb-0">
                Additional Assistance
              </p>
              <p class="mt-0">
                While our Hospital Privileging Coordinators (HPCs) are less involved once you’re on assignment, they’re still available to help if additional paperwork or document copies are needed for the medical staff office.
              </p>`,
        },
      ],
    }
  },

  methods: {
    closeOtherAccordions(id) {
      this.accordions.forEach((accordion) => {
        const ref = `what-to-expect-${accordion.id}-accordion-ref`

        if (ref !== `${id}-ref`) this.$refs[`what-to-expect-${accordion.id}-accordion-ref`][0].close()
      })
    },

    handleAccordionAction(state) {
      this.closeOtherAccordions(state.id)
    },

    toogleAccordion(accordion) {
      this.$refs[`what-to-expect-${accordion}-accordion-ref`][0].toggleClick()
    },
  },
}


import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
export default {
  name: 'StartTour',

  components: {
    PDEButton,
    PDEHeading,
  },

  methods: {
    startTour() {
      this.$dashboardTour.start()
    },
  },
}
